import * as classNames from 'classnames'
import * as _ from 'lodash'
import * as React from 'react'
import {CardsProps, CardsState} from '.'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {DH} from '../../utils/data-hooks'
import {Title} from '../list-title'
import {Card} from './card'
import * as cs from './card/card.scss'
import * as s from './cards.scss'
import {CardsConfig, Utils} from './utils'

export class Cards extends React.Component<CardsProps, CardsState> {
  state = {loaded: false}
  utils: Utils

  constructor(props: CardsProps) {
    super(props)
    this.utils = new Utils(props.showMembers)
  }

  resizeListener = () => {
    const config = this.getCardsConfig()
    this.utils.calculateCardsStyles(config)
    this.utils.resizeContainer(config)
  }

  debouncedResizeListener = _.debounce(this.resizeListener, 100)

  async componentDidMount() {
    this.utils.addCardsListeners(() => this.getCardsConfig())

    this.setState({loaded: true})
  }

  async componentDidUpdate(prevProps: CardsProps, prevState: CardsState) {
    const config = this.getCardsConfig()

    this.utils.addCardsListeners(() => this.getCardsConfig())
    this.utils.setStyles(config)
    this.utils.calculateCardsStyles(config)

    if (prevState.loaded) {
      this.utils.leaveCards(config)
      this.props.expand && !this.props.hideAdditionalComponents && this.utils.hoverFirstCard(config)
    }
  }

  getCardsConfig(): CardsConfig {
    const {
      showImage,
      hideAdditionalComponents,
      columns,
      cardMargins,
      cardBorderWidth,
      imageRatio,
      fullWidth,
      editor,
      site,
    } = this.props

    return {
      editor,
      site,
      fullWidth,
      showImage,
      hideAdditionalComponents,
      columns,
      cardMargins,
      cardBorderWidth,
      imageRatio,
      hoverClass: cs.hover,
      noTransitionsClass: s.noTransitions,
    }
  }

  render() {
    const {events, editor, t} = this.props
    const {loaded} = this.state

    this.utils.resetCards()

    return (
      <Resizable {...this.getCardsConfig()} resizeFunc={this.debouncedResizeListener}>
        <div
          className={classNames(s.container, {
            [s.hidden]: !loaded,
            [s.noTransitions]: editor,
          })}
          ref={element => this.utils.setContainer(element)}
        >
          <Title />
          <div className={s.cards} data-hook={DH.cards}>
            {events.map(event => (
              <div key={event.id} className={s.card} data-hook={DH.card} ref={element => this.utils.addCard(element)}>
                <Card event={event} t={t} />
              </div>
            ))}
          </div>
        </div>
      </Resizable>
    )
  }
}
