import {withNamespaces, WithNamespaces} from 'react-i18next'
import {
  getCalendarMonthWithEvents,
  getReferenceDate,
  CalendarMonthWithEvents,
} from '../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MobileMonthlyDays as MobileMonthlyDaysPresentation} from './mobile-monthly-days'

export interface MobileMonthlyDaysOwnProps {}

export interface MobileMonthlyDaysRuntimeProps {
  calendarDays: CalendarMonthWithEvents
  borderStyleTop: React.CSSProperties
  borderStyleBottom: React.CSSProperties
}

export interface MobileMonthlyDaysProps
  extends MobileMonthlyDaysRuntimeProps,
    MobileMonthlyDaysOwnProps,
    WithNamespaces {}

const mapRuntime = (context: AppProps): MobileMonthlyDaysRuntimeProps => ({
  calendarDays: getCalendarMonthWithEvents(context, getReferenceDate(context.state)),
  borderStyleTop: getBorderStyle(context, 'top'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDays = connect<MobileMonthlyDaysOwnProps, MobileMonthlyDaysRuntimeProps>(mapRuntime)(
  withNamespaces()(MobileMonthlyDaysPresentation),
)
