import {getFullMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../commons/selectors/environment'
import {addCalendarMonth, resetCalendar, subCalendarMonth} from '../../../actions/calendar-layout'
import {getReferenceDate} from '../../../selectors/calendar-layout'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {MonthlyCalendar as MonthlyCalendarPresentation} from './monthly-calendar'

export interface MonthlyCalendarOwnProps {}

export interface MonthlyCalendarRuntimeProps {
  referenceDate: Date
  fullMonthText: string
  resetCalendar: typeof resetCalendar
  addCalendarMonth: typeof addCalendarMonth
  subCalendarMonth: typeof subCalendarMonth
}

export interface MonthlyCalendarProps extends MonthlyCalendarRuntimeProps, MonthlyCalendarOwnProps, WithNamespaces {}

export interface MonthlyCalendarState {
  datesLoading: boolean
}

const mapRuntime = ({actions, state}: AppProps): MonthlyCalendarRuntimeProps => ({
  referenceDate: getReferenceDate(state),
  fullMonthText: getFullMonthText(getReferenceDate(state), getFullLocale(state)),
  addCalendarMonth: actions.addCalendarMonth,
  subCalendarMonth: actions.subCalendarMonth,
  resetCalendar: actions.resetCalendar,
})

export const MonthlyCalendar = connect<MonthlyCalendarOwnProps, MonthlyCalendarRuntimeProps>(mapRuntime)(
  withNamespaces()(MonthlyCalendarPresentation),
)
