import {isMobile} from '../../../../commons/selectors/environment'
import {isEventExternalRegistration} from '../../../../commons/selectors/event'
import {
  getRsvpButtonMargin,
  isHollowButton,
  isOnImageLayout,
  isRoundedButton,
  isRsvpLabelVisible,
} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {withMargin} from '../../hoc/with-margin'
import {connect} from '../../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'

interface RsvpButtonOwnProps {
  event: wix.events.Event
}

interface RsvpButtonStateProps {
  title: string
  text: string
  roundedButton: boolean
  hollowButton: boolean
  width: number
  height: number
  borderRadius: number
  roundedBorderRadius: number
  onImage: boolean
  isMobile: boolean
  navigateToPage: Function
}

export interface RsvpButtonProps extends RsvpButtonOwnProps, RsvpButtonStateProps {}

const mapState = ({state, actions}: AppProps, {event}: RsvpButtonOwnProps) => ({
  title: isRsvpLabelVisible(state) ? state.component.settings.texts.RSVPTitle : null,
  text: state.component.settings.texts.oneButton,
  roundedButton: isRoundedButton(state),
  hollowButton: isHollowButton(state),
  width: isMobile(state) ? undefined : state.component.settings.oneButtonWidth,
  height: isMobile(state) ? undefined : state.component.settings.buttonHeight,
  borderRadius: state.component.settings.buttonsBorderRadius,
  roundedBorderRadius: state.component.settings.roundedButtonsBorderRadius,
  onImage: isOnImageLayout(state),
  isMobile: isMobile(state),
  navigateToPage: () => {
    if (isEventExternalRegistration(event)) {
      window.open(event.registration.external.registration, '_blank')
    }
    actions.navigateToPage(event, state)
  },
})

const getMargin = ({state}: AppProps) => getRsvpButtonMargin(state)

export const RsvpButton = withMargin(
  connect<RsvpButtonOwnProps, RsvpButtonStateProps>(mapState)(RsvpButtonPresentation),
  getMargin,
)
