export const IMAGE_RATIO = {
  '1:1': 1,
  '16:9': 2,
}

export const LIST_ALIGNMENT = {
  LEFT: 1,
  CENTER: 2,
  RIGHT: 3,
}

export const ALIGNMENT = {
  LEFT: 1,
  CENTER: 2,
  RIGHT: 3,
}

export const LIST_IMAGE_LAYOUT = {
  LEFT: 1,
  MIXED: 2,
  RIGHT: 3,
}

export const LIST_LOCATION_AND_DATE_FORMAT = {
  COMPACT: 1,
  FULL: 2,
}

export const LOCATION_AND_DATE_FORMAT = {
  VERTICAL: 1,
  HORIZONTAL: 3,
  COMPACT: 2,
}

export const WIDGET_TYPE = {
  SINGLE: 1,
  LIST: 2,
}

export const LIST_LAYOUT = {
  LIST: 1,
  GRID: 2,
  NEW_GRID: 3,
  SIDE_BY_SIDE: 4,
  CALENDAR: 5,
}

export const WIDGET_LAYOUT = {
  FULL: 1,
  BUTTON_ONLY: 2,
  VERTICAL: 3,
  BACKGROUND: 4,
}

export const OLD_WIDGET_LAYOUT = {
  FULL: 'FULL',
  BUTTON_ONLY: 'BUTTON_ONLY',
  VERTICAL: 'VERTICAL',
  BACKGROUND: 'BACKGROUND',
  HORIZONTAL: 'HORIZONTAL', // deprecated
  RIGHT: 'RIGHT', // deprecated
}

export const HORIZONTAL_IMAGE_POSITION = {
  LEFT: 1,
  RIGHT: 2,
}

export const VERTICAL_IMAGE_POSITION = {
  TOP: 1,
  BOTTOM: 2,
}

export const VERTICAL_ALIGNMENT = {
  TOP: 1,
  CENTER: 2,
  BOTTOM: 3,
}

export const IMAGE_SCALING = {
  FIT: 1,
  CROP: 2,
}

export const BUTTONS_STYLE = {
  FULL: 1,
  HOLLOW: 2,
  FULL_ROUNDED: 3,
  HOLLOW_ROUNDED: 4,
}
