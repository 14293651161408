import {handleKeyPressEnterOrSpace} from '@wix/wix-events-commons-statics/dist/accessibility'
import {getAvatars, getExtraGuestsCount} from '@wix/wix-events-commons-statics/dist/members'
import classNames from 'classnames'
import * as React from 'react'
import {AvatarGroup, AvatarGroupSize} from 'wix-ui-tpa/AvatarGroup'
import {isRsvp} from '../../../commons/selectors/event'
import {DH} from '../../utils/data-hooks'
import {MembersProps} from './index'
import s from './members.st.css'

const MAX_AVATARS = 3

export const Members = ({event, onImage, singleLayout, calendarLayout, listLayout, onClick, t}: MembersProps) => {
  const items = getAvatars(event.members, event.totalGuests, isRsvp(event), MAX_AVATARS)
  const extraGuests = getExtraGuestsCount(event.totalGuests, MAX_AVATARS)

  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
    onClick(event.id)
  }

  let avatarsClass = s.avatarsDefault

  if (onImage) {
    avatarsClass = s.avatarsOnImage
  } else if (singleLayout) {
    avatarsClass = s.avatarsSingleLayout
  } else if (calendarLayout) {
    avatarsClass = s.avatarsCalendarLayout
  } else if (listLayout) {
    avatarsClass = s.avatarsListLayout
  }

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPressEnterOrSpace(handleClick)}
      tabIndex={0}
      aria-label={t('a11y.guests')}
      className={s.root}
    >
      <AvatarGroup
        {...{className: classNames(s.avatars, avatarsClass)}}
        maxAmount={MAX_AVATARS}
        items={items}
        size={AvatarGroupSize.small}
        data-hook={DH.members}
      >
        {extraGuests ? (
          <AvatarGroup.TextButton tabIndex={-1} className={s.avatar}>
            {t('members.moreLink', {amount: extraGuests})}
          </AvatarGroup.TextButton>
        ) : null}
      </AvatarGroup>
    </div>
  )
}
