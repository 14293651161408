export {SocialBar} from './social-bar'

export interface SocialBarProps {
  t: Function
  share: Function
  eventUrl: string
  hideTitle?: boolean
  spacing?: number
  row?: boolean
  className?: string
  iconFontSize?: number
  newIcons?: boolean
}
