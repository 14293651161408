import * as classNames from 'classnames'
import * as React from 'react'
import {Members} from '../../../members'
import {DescriptionProps} from './interfaces'
import * as s from './members.scss'

export const ListItemMembers = ({event, visible, mobile, onClick}: DescriptionProps) => {
  if (!visible) {
    return null
  }

  return (
    <div className={classNames(s.members, {[s.mobile]: mobile})} data-hook="ev-list-item-members" onClick={onClick}>
      <Members event={event} listLayout />
    </div>
  )
}
