import classNames from 'classnames'
import * as React from 'react'
import {Animator} from 'wix-animations'
import {ItemProps} from '.'
import {FullDateLocation} from '../../full-date-location'
import {RsvpButton} from '../../rsvp-button'
import {Date} from './date'
import {Description} from './description'
import {Image} from './image'
import {Members} from './members'
import * as s from './mobile-item.scss'
import {Share} from './share'
import {Title} from './title'

export const MobileItem = ({
  t,
  event,
  opened,
  hovered,
  showDate,
  showImage,
  showMembers,
  listLayout,
  squareImage,
  fullLocale,
  listShowFullDate,
  listShowLocation,
  listShowDescription,
  additionalComponentsHidden,
  mobile,
}: ItemProps) => {
  return (
    <>
      {(showImage || showDate) && (
        <div className={classNames(s.imageDate, {[s.imageOverlay]: showImage})}>
          <Image event={event} containerHeight={163} squareImage={squareImage} />
          <div className={classNames({[s.date]: showImage})}>
            <Date event={event} t={t} customColorClass={showImage ? s.white : ''} fullLocale={fullLocale} />
          </div>
        </div>
      )}
      <div className={s.contentContainer}>
        <Title
          active={hovered || opened}
          event={event}
          opened={opened}
          listLayout={listLayout}
          showArrow={!additionalComponentsHidden}
          mobile={mobile}
        />
        <Animator show={opened} height={calculateContentHeight}>
          <div className={classNames(s.content, listLayout ? s.listContentText : s.mobileCardsContentText)}>
            <FullDateLocation
              event={event}
              showDate={listShowFullDate}
              showLocation={listShowLocation}
              fullLocale={fullLocale}
            />
            <Members event={event} visible={showMembers} mobile={mobile} />
            <Description event={event} visible={listShowDescription} />
            <Share t={t} event={event} />
          </div>
        </Animator>
        <div style={{marginTop: opened ? 24 : 30, width: mobile ? '100%' : null}}>
          <RsvpButton event={event} fullWidth={mobile} />
        </div>
      </div>
    </>
  )
}

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
