import * as classNames from 'classnames'
import * as React from 'react'
import {TitleProps} from '.'
import {ExpandArrow} from '../../../../../icons/dist'
import * as sc from '../../../classes.scss'
import * as sd from '../date/date.scss'
import * as s from './title.scss'

export const Title = ({active, event, opened, listLayout, showArrow, withOverflow, mobile}: TitleProps) => {
  const classes = classNames(sc.textLineHeight, sd.listHeaderTitle, sd.color, {
    [s.listContentTitleColor]: active && listLayout,
    [s.mobile]: mobile,
    [s.overflow]: withOverflow,
  })

  return (
    <span className={classes}>
      <span className={s.title} data-hook="ev-list-item-title">
        {event.title}
      </span>
      {showArrow && (
        <span className={classNames(s.icon, {[s.reversedIcon]: opened})}>
          <ExpandArrow />
        </span>
      )}
    </span>
  )
}
