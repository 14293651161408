import {TranslationFunction} from 'i18next'
import {getFullLocale} from '../../../../commons/selectors/environment'
import {
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {SideBySideItem} from './side-by-side-item'

interface SideBySideItemStateProps {
  showImage: boolean
  showDate: boolean
  showVenue: boolean
  showFullDate: boolean
  showLocation: boolean
  showDescription: boolean
  showSocialBar: boolean
  showMembers: boolean
  imageLayout: number
  imagePosition: number
  imageOpacity: number
  imageWidth: number
  alignment: number
  locationAndDateFormat: number
  fullLocale: string
  membersEnabled: boolean
}

interface SideBySideItemOwnProps {
  event: ExtendedEvent
  evenItem: boolean
  t: TranslationFunction
}

export interface SideBySideItemProps extends SideBySideItemStateProps, SideBySideItemOwnProps {}

const mapRuntime = (
  {state, host: {dimensions}}: AppProps,
  {event}: SideBySideItemOwnProps,
): SideBySideItemStateProps => ({
  showImage: isImageVisible(state),
  showDate: isDateVisible(state),
  showVenue: isVenueVisible(state, dimensions),
  showFullDate: isFullDateVisible(state),
  showLocation: isLocationVisible(state),
  showDescription: isDescriptionVisible(state),
  showSocialBar: isSocialShareVisible(state),
  showMembers: isMembersVisible(state, event),
  imageLayout: state.component.settings.listImageLayout,
  imagePosition: state.component.settings.listImagePosition,
  imageOpacity: state.component.settings.listImageOpacity,
  imageWidth: state.component.settings.listImageWidth,
  alignment: state.component.settings.listAlignment,
  locationAndDateFormat: state.component.settings.listLocationAndDateFormat,
  fullLocale: getFullLocale(state),
  membersEnabled: state.membersEnabled,
})

export default connect<SideBySideItemOwnProps, SideBySideItemStateProps>(mapRuntime)(SideBySideItem)
