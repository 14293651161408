import {withStyles} from 'native-components-infra/dist/es/src'
import {ErrorPage} from '../../../commons/components/error-page'
import {withSentryErrorBoundary} from '../../../commons/components/hoc/sentry-error-boundary'
import {sentryConfig} from '../../utils/sentry'
import {App} from './app'

export default {
  component: withSentryErrorBoundary(
    withStyles(App, {cssPath: ['eventsWidget.min.css', 'eventsWidget.stylable.bundle.css']}),
    sentryConfig('events-widget-viewer'),
    ErrorPage,
  ),
}
