import * as i18next from 'i18next'

export default function i18n(locale, resources) {
  return (
    i18next
      /*    .use({
      type: 'backend',
      read: (language, namespace, callback) => {
        // We configure how i18next should fetch a translation resource when it
        // needs it: We use Webpack's dynamic imports to fetch resources without
        // increasing our bundle size.
        //
        // See https://webpack.js.org/guides/code-splitting/#dynamic-imports for
        // more information.
        return import(`./locales/messages_${language}.json`)
          .then(translation => callback(null, translation))
          .catch(error => callback(error))
      }
    })*/
      .init({
        resources,

        // Initial language
        lng: locale,

        // Fallback language
        fallbackLng: 'en',

        // use format like lt-lt, instead of lt-LT
        lowerCaseLng: true,

        // Don't use a key separator (no support for nested translation objects)
        keySeparator: false,

        // Wait for translation data to be available before rendering a component
        react: {
          wait: true,
        },
      })
  )
}
