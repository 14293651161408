import {getDateInTimezone, getStartOfDay, sameMonth} from '@wix/wix-events-commons-statics/dist/date/date'
import {
  formatDates,
  getFullDate,
  getMomentDate,
  getShortDate,
  getTime,
  isYearHidden,
} from '@wix/wix-events-commons-statics/dist/date/formatter'
import {getFullLocale} from '../../commons/selectors/environment'
import {State} from '../types/state'

export const getFormattedStartDate = (config: wix.events.ScheduleConfig, fullLocale: string) => {
  const {scheduleTbdMessage, scheduleTbd, startDate, timeZoneId, showTimeZone} = config

  if (scheduleTbd) {
    return scheduleTbdMessage
  }

  const showYear = !isYearHidden(getMomentDate(startDate, timeZoneId), undefined)

  return getFullDate(startDate, timeZoneId, showYear, fullLocale, showTimeZone)
}

export const getFormattedShortStartDate = (config: wix.events.ScheduleConfig, fullLocale: string) => {
  const {scheduleTbdMessage, scheduleTbd, startDate, timeZoneId} = config

  if (scheduleTbd) {
    return scheduleTbdMessage
  }

  return getShortDate(startDate, timeZoneId, fullLocale)
}

export const getFormattedStartTime = (state: State, config: wix.events.ScheduleConfig) =>
  getTime(config.startDate, config.timeZoneId, getFullLocale(state))

export const getFormattedDate = (config: wix.events.ScheduleConfig, fullLocale: string) => {
  let {startDate, endDate, timeZoneId, scheduleTbd, scheduleTbdMessage, endDateHidden, showTimeZone} = config

  if (endDateHidden) {
    endDate = null
  }

  return scheduleTbd ? scheduleTbdMessage : formatDates(startDate, endDate, timeZoneId, false, fullLocale, showTimeZone)
}

export const getFormattedLocation = (location: wix.events.Location) => location.name || location.address

export const getFormattedFullLocation = (location: wix.events.Location) =>
  location.address && location.name ? `${location.name}, ${location.address}` : getFormattedLocation(location)

export const getEventById = (state: State, eventId: string) => getEvents(state).find(event => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  if (state.siteSettings.firstEventCreated || state.events.length) {
    return state.events
  }

  return state.demoEvents[state.component.settings.widgetType] as ExtendedEvent[]
}

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map(event => event.id)

export const getGroupedEventsByDate = (state: State, monthReferenceDate?: Date) => {
  const eventsMap: {[date: string]: ExtendedEvent[]} = {}

  getEvents(state).forEach(event => {
    const {startDate, timeZoneId} = event.scheduling.config

    if (startDate) {
      if (monthReferenceDate && !sameMonth(startDate, monthReferenceDate)) {
        return
      }

      const startDateString = getStartOfDay(getDateInTimezone(startDate, timeZoneId), true).toISOString()

      eventsMap[startDateString] = eventsMap[startDateString] || []
      eventsMap[startDateString].push(event)
    }
  })

  return eventsMap
}
