import {withNamespaces, WithNamespaces} from 'react-i18next'
import {openMembersModal} from '../../actions/members'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Members as MembersPresentation} from './members'

export interface MembersProps extends MembersOwnProps, MembersStateProps, WithNamespaces {}

interface MembersOwnProps {
  event: ExtendedEvent
  singleLayout?: boolean
  calendarLayout?: boolean
  listLayout?: boolean
  onImage?: boolean
}

interface MembersStateProps {
  onClick: typeof openMembersModal
}

const mapRuntime = ({actions}: AppProps) => ({
  onClick: actions.openMembersModal,
})

export const Members = connect<MembersOwnProps, MembersStateProps>(mapRuntime)(withNamespaces()(MembersPresentation))
