import {
  compare,
  compensateBrowserTimezone,
  getCalendarMonth,
  getDayOfMonth,
  getStartOfDay,
  getWeekDay,
  sameDay,
  CalendarDay,
  WEEKDAY,
} from '@wix/wix-events-commons-statics/dist/date/date'
import {getDateInTimezone} from '@wix/wix-events-commons-statics/dist/date/date'
import {IStyle} from 'native-components-infra/dist/es/src/types/types'
import {isEditor, isMobile} from '../../commons/selectors/environment'
import {AppProps} from '../components/app/interfaces'
import {State} from '../types/state'
import {getEvents, getFormattedStartTime, getGroupedEventsByDate} from './events'

export const getCalendarWeeksWithEvents = (
  {state}: AppProps,
  referenceDate: Date = new Date(),
  weekStartDay?: WEEKDAY,
): CalendarWeeksWithEvents => {
  const eventsMap = getGroupedEventsByDate(state)

  return getCalendarMonth(referenceDate, weekStartDay).map(week =>
    week.map(day => ({
      ...day,
      events: eventsMap[getStartOfDay(day.date).toISOString()] || [],
    })),
  )
}

export const getReferenceDate = (state: State): Date => {
  if (state.calendarLayout.referenceDate) {
    return state.calendarLayout.referenceDate
  } else if (isMobile(state)) {
    return getMobileReferenceDate(state)
  } else if (isEditor(state)) {
    return getEditorReferenceDate(state)
  } else {
    return getStartOfDay(new Date())
  }
}

export const getEditorReferenceDate = (state: State): Date => {
  const now = getStartOfDay(new Date())

  const groupedEvents = Object.entries(getGroupedEventsByDate(state))
  const groupedEventsFromToday = groupedEvents.filter(([date]) => compare(now, date) <= 0)
  const groupedEventsTillToday = groupedEvents.filter(([date]) => compare(now, date) > 0).reverse()

  const multipleEventsInDay = ([, dayEvents]) => dayEvents.length > 1
  const anyEvent = ([, dayEvents]) => Boolean(dayEvents.length)

  const searches = [
    () => groupedEventsFromToday.find(multipleEventsInDay),
    () => groupedEventsTillToday.find(multipleEventsInDay),
    () => groupedEventsFromToday.find(anyEvent),
    () => groupedEventsTillToday.find(anyEvent),
  ]

  return (
    searches.reduce(
      (referenceDate, search) => {
        if (!referenceDate) {
          const eventsEntry = search()
          return eventsEntry && new Date(eventsEntry[0])
        } else {
          return referenceDate
        }
      },
      null as Date,
    ) || now
  )
}

export const getMobileReferenceDate = (state: State): Date => {
  const now = getStartOfDay(new Date())

  const upcomingDateWithEvents = Object.entries(getGroupedEventsByDate(state))
    .filter(([date]) => compare(now, date) <= 0)
    .find(([, dayEvents]) => Boolean(dayEvents.length))

  return upcomingDateWithEvents ? new Date(upcomingDateWithEvents[0]) : now
}

export const getCalendarMonthWithEvents = (
  {state}: AppProps,
  referenceDate: Date = new Date(),
): CalendarMonthWithEvents =>
  Object.entries(getGroupedEventsByDate(state, referenceDate)).reduce(
    (monthWithEvents, [date, dayEvents]) => {
      monthWithEvents.push({
        events: dayEvents,
        date: new Date(date),
        day: getDayOfMonth(date),
        weekDay: getWeekDay(date),
        isCurrentMonth: true,
        today: sameDay(new Date(), date),
        past: compare(date, getStartOfDay(new Date())) === -1,
      })

      return monthWithEvents
    },
    [] as CalendarMonthWithEvents,
  )

export const getSelectedDayEvents = ({state}: AppProps): EventWithTimeText[] =>
  getEvents(state)
    .filter(({scheduling: {config: {startDate, timeZoneId}}}) =>
      sameDay(
        compensateBrowserTimezone(getDateInTimezone(startDate, timeZoneId)),
        state.calendarLayout.monthly.selectedDate,
      ),
    )
    .map(event => ({
      ...event,
      timeText: getFormattedStartTime(state, event.scheduling.config),
    }))

export const getSelectedDayEventsCount = ({state}: AppProps): number =>
  getEvents(state).filter(({scheduling: {config: {startDate, timeZoneId}}}) =>
    sameDay(
      compensateBrowserTimezone(getDateInTimezone(startDate, timeZoneId)),
      state.calendarLayout.monthly.selectedDate,
    ),
  ).length

export const getEventDetailsStyleHash = (context: AppProps) => {
  const eventDetailsSettings = {
    booleans: ['listShowImage', 'listShowFullDate', 'listShowLocation', 'listShowDescription', 'listShowSocialShare'],
    numbers: [
      'calendarDetailsTitleTextSize',
      'calendarDetailsDateLocationTextSize',
      'calendarDetailsDescriptionTextSize',
    ],
  }

  const style: IStyle = context.host.style

  const settings = Object.entries(style.styleParams).reduce((result, [type, params]) => {
    if (eventDetailsSettings[type]) {
      result[type] = Object.entries(params).reduce((typeResult, [key, value]) => {
        if (eventDetailsSettings[type].includes(key)) {
          typeResult[key] = value
        }

        return typeResult
      }, {})
    }

    return result
  }, {})

  return JSON.stringify(settings)
}

export interface CalendarDayWithEvents extends CalendarDay {
  events: ExtendedEvent[]
}

export type CalendarMonthWithEvents = CalendarDayWithEvents[]
export type CalendarWeekWithEvents = CalendarDayWithEvents[]
export type CalendarWeeksWithEvents = CalendarWeekWithEvents[]

export interface EventWithTimeText extends wix.events.Event {
  timeText: string
}
