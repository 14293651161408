import * as DateFormatter from '@wix/wix-events-commons-statics/dist/date/formatter'
import * as classNames from 'classnames'
import * as React from 'react'
import {DateProps} from '.'
import {getFormattedDate} from '../../../../selectors/events'
import * as sc from '../../../classes.scss'
import * as s from './date.scss'

export const dateWidth = 108

export const Date = ({event, t, fullLocale, customColorClass = ''}: DateProps) => {
  const {
    scheduling: {
      config: {startDate, timeZoneId, scheduleTbd},
    },
  } = event

  if (scheduleTbd) {
    return (
      <div
        className={classNames(s.listHeaderTitle, customColorClass || s.color)}
        style={{minWidth: dateWidth}}
        data-hook="ev-date-tbd"
      >
        {t('listDateTbd')}
      </div>
    )
  }

  const monthDay = DateFormatter.getDay(startDate, timeZoneId, fullLocale)
  const weekDay = DateFormatter.getWeekDay(startDate, timeZoneId, fullLocale)
  const month = DateFormatter.getMonth(startDate, timeZoneId, fullLocale)

  return (
    <div
      className={classNames(s.listHeaderTitle, sc.row, customColorClass || s.color)}
      style={{minWidth: dateWidth}}
      aria-label={getFormattedDate(event.scheduling.config, fullLocale)}
    >
      <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-month-day" aria-hidden="true">
        {monthDay}
      </div>
      <div className={classNames(sc.textNoMargin, s.weekAndMonth)}>
        <div
          className={s.weekDay}
          style={customColorClass ? null : {opacity: 0.5}}
          data-hook="ev-date-weekday"
          aria-hidden="true"
        >
          {weekDay}
        </div>
        <div className={s.month} data-hook="ev-date-month" aria-hidden="true">
          {month}
        </div>
      </div>
    </div>
  )
}
