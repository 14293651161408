import {renderIfVisible} from '../../hoc/render-if-visible'

import {isMembersVisible} from '../../../selectors/settings'
import {SingleEventMembers} from './members'

export interface SingleEventMembersProps {
  event: ExtendedEvent
  onImage: boolean
}

export const Members = renderIfVisible(SingleEventMembers, isMembersVisible)
