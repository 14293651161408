import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isListLayout} from '../../selectors/list-settings'
import {isSingleEventWidget} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {EmptyState as EmptyStatePresentation} from './empty-state'

interface EmptyStateStateProps {
  singleEventWidget: boolean
  listLayout: boolean
}

export interface EmptyStateProps extends EmptyStateStateProps, WithNamespaces {}

const mapRuntimeToProps = ({state}: AppProps): EmptyStateStateProps => ({
  singleEventWidget: isSingleEventWidget(state),
  listLayout: isListLayout(state),
})

export const EmptyState = connect<{}, EmptyStateStateProps>(mapRuntimeToProps)(withNamespaces()(EmptyStatePresentation))
