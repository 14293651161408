import {getFullLocale} from '../../../../../commons/selectors/environment'
import {getFormattedDate} from '../../../../selectors/events'
import {
  getDateMargin,
  getLocationMargin,
  isDateVisible,
  isHorizontalDividersVisible,
  isLabelsVisible,
  isLocationVisible,
  isOnImageLayout,
} from '../../../../selectors/settings'
import {AppProps} from '../../../app/interfaces'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {withMargin} from '../../../hoc/with-margin'
import {connect} from '../../../runtime-context/connect'
import {Presentation} from './presentation'

interface PresentationOwnProps {
  event: wix.events.Event
  dataHook?: string
}

interface PresentationStateProps {
  text: string
  text2?: string
  title: string
  titleVisible: boolean
  dividerVisible: boolean
  dividerWidth: number
  dividerHeight: number
  onImage: boolean
}

export interface PresentationProps extends PresentationOwnProps, PresentationStateProps {}

const mapLocationState = ({state, actions}, ownProps: PresentationOwnProps): PresentationStateProps => ({
  text: ownProps.event.location.name,
  text2: ownProps.event.location.address,
  title: state.component.settings.texts.location,
  titleVisible: isLabelsVisible(state),
  dividerVisible: isHorizontalDividersVisible(state),
  dividerWidth: state.component.settings.horizontalDividerWidth,
  dividerHeight: state.component.settings.horizontalDividerHeight,
  onImage: isOnImageLayout(state),
})

const mapDateState = ({state, actions}, ownProps: PresentationOwnProps): PresentationStateProps => ({
  text: getFormattedDate(ownProps.event.scheduling.config, getFullLocale(state)),
  title: state.component.settings.texts.time,
  titleVisible: isLabelsVisible(state),
  dividerVisible: isHorizontalDividersVisible(state),
  dividerWidth: state.component.settings.horizontalDividerWidth,
  dividerHeight: state.component.settings.horizontalDividerHeight,
  onImage: isOnImageLayout(state),
})

const locationMargin = ({state}: AppProps) => getLocationMargin(state)

export const Location = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapLocationState)(Presentation), locationMargin),
  isLocationVisible,
)

const dateMargin = ({state}: AppProps) => getDateMargin(state)

export const Date = renderIfVisible(
  withMargin(connect<PresentationOwnProps, PresentationStateProps>(mapDateState)(Presentation), dateMargin),
  isDateVisible,
)
