import * as classNames from 'classnames'
import * as React from 'react'
import {FullDateLocationProps} from '.'
import {getFormattedDate, getFormattedFullLocation} from '../../selectors/events'
import {DH} from '../../utils/data-hooks'
import * as s from './full-date-location.scss'

export class FullDateLocation extends React.Component<FullDateLocationProps> {
  getContainerClasses = () => classNames({[s.noDate]: !this.props.showDate, [s.noLocation]: !this.props.showLocation})

  render() {
    const {scheduling, location} = this.props.event

    return (
      <div className={this.getContainerClasses()} data-hook={DH.fullDateLocation}>
        <div className={s.date} data-hook="date">
          {getFormattedDate(scheduling.config, this.props.fullLocale)}
        </div>
        <div className={s.location} data-hook="location">
          {getFormattedFullLocation(location)}
        </div>
      </div>
    )
  }
}
