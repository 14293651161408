import * as classNames from 'classnames'
import {OverflowYProperty} from 'csstype'
import * as React from 'react'
import {ListLayoutProps} from '.'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import {ItemContainer as Item} from './item-container'
import * as s from './list-layout.scss'
import {resizeContainer} from './utils'

export class ListLayout extends React.Component<ListLayoutProps> {
  render() {
    const {events, t, narrow, mobile, listLayout} = this.props
    const style = {
      padding: narrow ? '0 20px' : '0 30px',
      overflowY: narrow && !mobile ? 'scroll' : ('hidden' as OverflowYProperty),
      height: narrow && !mobile ? '100%' : 'auto',
    }

    const className = classNames(
      s.container,
      s.scrollBarColor,
      mobile && !listLayout ? s.cardContainer : s.listContainer,
    )

    return (
      <Resizable resizeFunc={resizeContainer}>
        <div className={className} style={style} data-hook="ev-list-layout">
          <Title />
          {events.map((event: ExtendedEvent, index: number) => (
            <Item key={event.id} event={event} t={t} lastItem={events.length - 1 === index} />
          ))}
        </div>
      </Resizable>
    )
  }
}
