import {TranslationFunction} from 'i18next'
import {getFullLocale, isMobile} from '../../../../commons/selectors/environment'
import {isItemOpened} from '../../../selectors/layout'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isListLayout,
  isLocationVisible,
  isMembersVisible,
  isMobileSquareImage,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {DesktopItem as DesktopItemPresentation} from './desktop-item'
import {MobileItem as MobileItemPresentation} from './mobile-item'

export interface ItemProps extends ItemOwnProps, ItemStateProps {}

interface ItemOwnProps {
  t: TranslationFunction
  event: ExtendedEvent
  hovered: boolean
}

interface ItemStateProps {
  showImage: boolean
  showDate: boolean
  showMembers: boolean
  opened: boolean
  listShowFullDate: boolean
  listShowLocation: boolean
  listShowDescription: boolean
  showVenue: boolean
  additionalComponentsHidden: boolean
  listLayout: boolean
  squareImage: boolean
  fullLocale: string
  mobile: boolean
  rtl: boolean
}

const mapRuntime = ({state, actions, host: {dimensions}, isRTL}: AppProps, {event}: ItemOwnProps): ItemStateProps => ({
  showImage: isImageVisible(state),
  showDate: isDateVisible(state),
  showMembers: isMembersVisible(state, event),
  opened: isItemOpened(state, event.id),
  listShowFullDate: isFullDateVisible(state),
  listShowLocation: isLocationVisible(state),
  listShowDescription: isDescriptionVisible(state),
  showVenue: isVenueVisible(state, dimensions),
  additionalComponentsHidden: isAdditionalComponentsHidden(state, event.id),
  listLayout: isListLayout(state),
  squareImage: isMobileSquareImage(state),
  fullLocale: getFullLocale(state),
  mobile: isMobile(state),
  rtl: isRTL,
})

export const DesktopItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(DesktopItemPresentation)

export const MobileItem = connect<ItemOwnProps, ItemStateProps>(mapRuntime)(MobileItemPresentation)
