import {isMobile} from '../../../../commons/selectors/environment'
import {isFitImage, isImageVisible} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {connect} from '../../runtime-context/connect'
import {Image as ImagePresentation} from './image'

interface ImageOwnProps {
  event: wix.events.Event
}

interface ImageStateProps {
  layout: number
  size: number
  gridPosition: number
  opacity: number
  fitImage: boolean
  isMobile: boolean
}

export interface ImageProps extends ImageOwnProps, ImageStateProps {}

const mapState = ({state}: AppProps): ImageStateProps => ({
  layout: state.component.settings.widgetLayout,
  size: state.component.settings.imageWidth,
  gridPosition: state.component.settings.imageGridPosition,
  opacity: state.component.settings.imageOpacity,
  fitImage: isFitImage(state),
  isMobile: isMobile(state),
})

export const Image = renderIfVisible(
  connect<ImageOwnProps, ImageStateProps>(mapState)(ImagePresentation),
  isImageVisible,
)
