import {hasGuestsGoing} from '@wix/wix-events-commons-statics/dist/members'
import {isMobile} from '../../commons/selectors/environment'
import {isRsvp} from '../../commons/selectors/event'
import {
  BUTTONS_STYLE,
  IMAGE_RATIO,
  IMAGE_SCALING,
  LIST_LAYOUT,
  LOCATION_AND_DATE_FORMAT,
  WIDGET_LAYOUT,
  WIDGET_TYPE,
} from '../constants/settings'
import {State} from '../types/state'
import {getFirstEvent} from './events'

export const getWidgetType = (state: State) => state.component.settings.widgetType

export const isSingleEventWidget = (state: State) => getWidgetType(state) === WIDGET_TYPE.SINGLE

export const isHorizontalLayout = (state: State) => state.component.settings.widgetLayout === WIDGET_LAYOUT.FULL

export const isVerticalLayout = (state: State) => state.component.settings.widgetLayout === WIDGET_LAYOUT.VERTICAL

export const isOnImageLayout = (state: State) =>
  state.component.settings.widgetLayout === WIDGET_LAYOUT.BACKGROUND && isImageVisible(state)

export const includeImageToCalculations = (state: State) => {
  const verticalLayout = isVerticalLayout(state)
  const onImageLayout = isOnImageLayout(state)
  const buttonOnlyLayout = isButtonLayout(state)

  return isImageVisible(state) && (isMobile(state) ? !onImageLayout && !buttonOnlyLayout : verticalLayout)
}

export const isButtonLayout = (state: State) => state.component.settings.widgetLayout === WIDGET_LAYOUT.BUTTON_ONLY

export const getImageRatio = (state: State): any => {
  const ratio = state.component.settings.imageRatio
  return Object.entries(IMAGE_RATIO).find(([key, value]) => value === ratio)[0] as '1:1' | '16:9'
}

export const isAllComponentsHidden = (state: State) =>
  !isCountdownVisible(state) &&
  !isTitleVisible(state) &&
  !isDescriptionVisible(state) &&
  !isDateVisible(state) &&
  !isLocationVisible(state) &&
  !isShareOptionsVisible(state)

export const isOnlyButtonVisible = (state: State) => isButtonLayout(state) || isAllComponentsHidden(state)

export const isImageVisible = (state: State) => {
  const event = getFirstEvent(state)

  return state.component.settings.showImage && !!(event.mainImage && event.mainImage.id) && !isOnlyButtonVisible(state)
}

export const isCountdownVisible = (state: State) =>
  state.component.settings.showCountdown &&
  !getFirstEvent(state).scheduling.config.scheduleTbd &&
  !isButtonLayout(state)

export const isTitleVisible = (state: State) => state.component.settings.showTitle && !isButtonLayout(state)

export const isDescriptionVisible = (state: State) =>
  state.component.settings.showDescription && !!getFirstEvent(state).description && !isButtonLayout(state)

export const isMembersVisible = (state: State, event: ExtendedEvent) =>
  state.membersEnabled &&
  !isButtonLayout(state) &&
  hasGuestsGoing(event.totalGuests, isRsvp(event)) &&
  state.component.settings.showMembers

export const isDateVisible = (state: State) => state.component.settings.showDate && !isButtonLayout(state)

export const isLocationVisible = (state: State) => state.component.settings.showLocation && !isButtonLayout(state)

export const isShareOptionsVisible = (state: State) =>
  state.component.settings.showShareOptions && !isButtonLayout(state)

export const isCompactDividerVisible = (state: State) =>
  state.component.settings.showCompactDividers &&
  state.component.settings.showDescription &&
  isCompactDateAndLocationVisible(state)

export const isCompactDateAndLocationVisible = (state: State) =>
  isCompactDateAndLocation(state) && isDateOrLocationVisible(state)

export const isLabelsVisible = (state: State) => state.component.settings.showLabels

export const isCompactDateAndLocation = (state: State) =>
  state.component.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.COMPACT

export const isDateOrLocationVisible = (state: State) => isDateVisible(state) || isLocationVisible(state)

export const getCompactDateAndLocationMargin = (state: State) => {
  if (isMobile(state)) {
    return isDescriptionVisible(state) ? 40 : 30
  }

  return state.component.settings.dateAndLocationMarginBottom
}

export const getCountdownMargin = (state: State) =>
  isMobile(state) ? 42 : state.component.settings.countdownMarginBottom

export const getDateAndLocationMargin = (state: State) => state.component.settings.dateAndLocationMarginBottom

export const isDateAndLocationVisible = (state: State) =>
  !isCompactDateAndLocation(state) && isDateOrLocationVisible(state)

export const isHorizontalDateAndLocation = (state: State) =>
  state.component.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.HORIZONTAL

export const isVerticalDateAndLocation = (state: State) =>
  state.component.settings.locationAndDateFormat === LOCATION_AND_DATE_FORMAT.VERTICAL

export const isVerticalDividersVisible = (state: State) =>
  state.component.settings.showFullDividers &&
  isHorizontalDateAndLocation(state) &&
  isDateVisible(state) &&
  isLocationVisible(state)

export const isHorizontalDividersVisible = (state: State) =>
  state.component.settings.showHorizontalDividers && isVerticalDateAndLocation(state)

export const getDescriptionMargin = (state: State) => {
  if (isMobile(state)) {
    return isCompactDateAndLocation(state) ? 54 : 42
  }

  return state.component.settings.descriptionMarginBottom
}

export const getTitleMargin = (state: State) => {
  if (isMobile(state)) {
    return !isDateVisible(state) && !isLocationVisible(state) && !isDescriptionVisible(state) ? 50 : 30
  }

  return state.component.settings.titleMarginBottom
}

export const getDateMargin = (state: State) => {
  if (isMobile(state)) {
    return isHorizontalDateAndLocation(state) ? 0 : isLabelsVisible(state) ? 40 : 24
  }

  return isHorizontalDateAndLocation(state) ? 0 : state.component.settings.dateMarginBottom
}

export const getRsvpButtonMargin = (state: State) =>
  isOnlyButtonVisible(state) ? 0 : state.component.settings.rsvpMarginBottom

export const getLocationMargin = (state: State) =>
  isHorizontalDateAndLocation(state) ? 0 : state.component.settings.locationMarginBottom

export const isHollowButton = (state: State) =>
  [BUTTONS_STYLE.HOLLOW, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(state.component.settings.buttonsStyle)

export const isRoundedButton = (state: State) =>
  [BUTTONS_STYLE.FULL_ROUNDED, BUTTONS_STYLE.HOLLOW_ROUNDED].includes(state.component.settings.buttonsStyle)

export const isRsvpLabelVisible = (state: State) => state.component.settings.showRSVPTitle

export const isFitImage = (state: State) => state.component.settings.imageScaling === IMAGE_SCALING.FIT

export const isListLayout = (state: State) => state.component.settings.listLayout === LIST_LAYOUT.LIST
