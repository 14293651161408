import {
  SocialBar as SocialBarCommons,
  SocialBarProps as SocialBarCommonsProps,
} from '../../../commons/components/social-bar'
import {getEventUrl} from '../../../commons/selectors/event'
import {shareEvent} from '../../../commons/services/share'
import {isShareOptionsVisible} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {renderIfVisible} from '../hoc/render-if-visible'
import {connect} from '../runtime-context/connect'

export interface SocialBarConnectProps {
  event: wix.events.Event
  hideTitle?: boolean
}

export interface SocialBarStateProps {
  share: Function
  eventUrl: string
  hideTitle: boolean
}

export interface SocialBarProps extends Partial<SocialBarCommonsProps>, SocialBarConnectProps {}

const mapRuntime = ({pageUrl, state}: AppProps, {event, hideTitle}: SocialBarConnectProps) => {
  return {
    share: shareEvent,
    eventUrl: getEventUrl(pageUrl.url, event, state.multilingual),
    hideTitle: state.membersEnabled || hideTitle,
  }
}

export const SocialBar = connect<SocialBarProps, SocialBarStateProps>(mapRuntime)(SocialBarCommons)

export const SingleEventSocialBar = renderIfVisible<SocialBarProps>(SocialBar, isShareOptionsVisible)
