import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFormattedStartTime} from '../../../../../../selectors/events'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {DayEvents as DayEventsPresentation} from './day-events'

export interface EventInfoOwnProps {
  events: wix.events.Event[]
}

export interface EventInfoRuntimeProps {
  firstEventTime: string
}

export interface EventInfoProps extends EventInfoRuntimeProps, EventInfoOwnProps, WithNamespaces {}

const mapRuntime = (context: AppProps, ownProps: EventInfoOwnProps): EventInfoRuntimeProps => ({
  firstEventTime: ownProps.events[0] && getFormattedStartTime(context.state, ownProps.events[0].scheduling.config),
})

export const DayEvents = connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(
  withNamespaces()(DayEventsPresentation),
)
