import {isMobile} from '../../../commons/selectors/environment'
import {isMainTitleVisible} from '../../selectors/list-settings'
import {isListLayout} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Title as TitlePresentation} from './title'

interface TitleStateProps {
  title: string
  listLayout: boolean
  listStripWidth: number
  mobile: boolean
  visible: boolean
}

export interface TitleProps extends TitleStateProps {}

const mapRuntimeToContext = ({state}: AppProps): TitleStateProps => ({
  title: state.component.settings.texts.listGeneralTitle,
  listLayout: isListLayout(state),
  listStripWidth: state.component.settings.listStripWidth,
  mobile: isMobile(state),
  visible: isMainTitleVisible(state),
})

export const Title = connect<{}, TitleStateProps>(mapRuntimeToContext)(TitlePresentation)
