import * as classNames from 'classnames'
import * as React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {MonthlyCalendarProps, MonthlyCalendarState} from '.'
import {Title} from '../../list-title'
import {TodayButton} from '../today-button'
import {MonthlyCalendarGrid} from './grid'
import * as s from './monthly-calendar.scss'

export class MonthlyCalendar extends React.PureComponent<MonthlyCalendarProps, MonthlyCalendarState> {
  state: MonthlyCalendarState = {
    datesLoading: true,
  }

  componentDidMount() {
    this.setState({datesLoading: false})
  }

  render() {
    const {addCalendarMonth, subCalendarMonth, resetCalendar, fullMonthText, referenceDate, t} = this.props

    return referenceDate ? (
      <div className={s.monthlyCalendar} data-hook={'monthly-desktop-calendar'}>
        <Title />
        <div className={s.controls}>
          <div className={s.monthControls}>
            <button
              onClick={() => subCalendarMonth()}
              type="button"
              className={s.monthButton}
              data-hook={'calendar-previous-month-button'}
              aria-label={t('a11y.previousMonth')}
            >
              <ChevronLeft size="1.5em" />
            </button>
            <div className={classNames(s.month, {[s.loading]: this.state.datesLoading})} data-hook={'calendar-month'}>
              {fullMonthText}
            </div>
            <button
              onClick={() => addCalendarMonth()}
              type="button"
              className={s.monthButton}
              data-hook={'calendar-next-month-button'}
              aria-label={t('a11y.nextMonth')}
            >
              <ChevronRight size="1.5em" />
            </button>
          </div>
          <div className={s.today}>
            <TodayButton onClick={resetCalendar} dataHook={'calendar-today-button'} />
          </div>
        </div>
        <div className={s.grid}>
          <MonthlyCalendarGrid />
        </div>
      </div>
    ) : null
  }
}
