import {TranslationFunction} from 'i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {closeListLayoutItems, openListLayoutItem} from '../../../actions/layout'
import {isItemOpened} from '../../../selectors/layout'
import {isAdditionalComponentsHidden, isListLayout, isNarrow} from '../../../selectors/list-settings'
import {connect} from '../../runtime-context/connect'
import {ItemContainer as ItemContainerPresentation} from './item'

interface ItemContainerStateProps {
  additionalComponentsHidden: boolean
  listDividerWidth: number
  listStripWidth: number
  opened: boolean
  mobile: boolean
  close: typeof closeListLayoutItems
  open: typeof openListLayoutItem
  listLayout: boolean
}

interface ItemContainerOwnProps {
  event: ExtendedEvent
  t: TranslationFunction
  lastItem: boolean
}

export interface ItemContainerProps extends ItemContainerStateProps, ItemContainerOwnProps {}

const mapRuntime = ({state, actions, host: {dimensions}}, ownProps: ItemContainerOwnProps) => ({
  listDividerWidth:
    isMobile(state) && !isListLayout(state)
      ? state.component.settings.cardBorderWidth
      : state.component.settings.listDividerWidth,
  listStripWidth: state.component.settings.listStripWidth,
  additionalComponentsHidden: isAdditionalComponentsHidden(state, ownProps.event.id),
  opened: isItemOpened(state, ownProps.event.id),
  mobile: isNarrow(state, dimensions),
  listLayout: isListLayout(state),
  close: actions.closeListLayoutItems,
  open: actions.openListLayoutItem,
})

export const ItemContainer = connect<ItemContainerOwnProps, ItemContainerStateProps>(mapRuntime)(
  ItemContainerPresentation,
)
