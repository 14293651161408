import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isEditor} from '../../../commons/selectors/environment'
import {getElementWidth} from '../../../commons/utils/dom'
import {getEvents} from '../../selectors/events'
import {isImageVisible} from '../../selectors/list-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SideBySide as SideBySidePresentation} from './side-by-side'

interface SideBySideStateProps {
  events: ExtendedEvent[]
  showImage: boolean
  editor: boolean
  margins: number
  width: number
}

interface SideBySideOwnProps {}

export interface SideBySideProps extends SideBySideStateProps, SideBySideOwnProps, WithNamespaces {}

const mapRuntime = ({state, host: {dimensions}}: AppProps): SideBySideStateProps => ({
  events: getEvents(state),
  showImage: isImageVisible(state),
  editor: isEditor(state),
  margins: state.component.settings.sideBySideLayoutMargins,
  width: dimensions.width || getElementWidth(`#${state.component.id}`),
})

export const SideBySide = connect<SideBySideOwnProps, SideBySideStateProps>(mapRuntime)(
  withNamespaces()(SideBySidePresentation),
)
