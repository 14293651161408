import * as React from 'react'
import {Members} from '../../members'
import {SingleEventMembersProps} from './index'
import * as s from './members.scss'

export const SingleEventMembers = ({event, onImage}: SingleEventMembersProps) => {
  return (
    <div className={s.members} data-hook="ev-members">
      <Members event={event} singleLayout={true} onImage={onImage} />
    </div>
  )
}
