import {isMobile} from '../../../commons/selectors/environment'
import {isEmptyStateNotificationVisible} from '../../selectors/list-settings'
import {isSingleEventWidget} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Layout as Presentation} from './layout'

interface LayoutStateProps {
  listLayout: number
  singleEventWidget: boolean
  emptyStateVisible: boolean
  mobile: boolean
}

interface LayoutOwnProps {}

export interface LayoutProps extends LayoutStateProps, LayoutOwnProps {}

const mapRuntime = ({state}: AppProps): LayoutStateProps => ({
  listLayout: state.component.settings.listLayout,
  singleEventWidget: isSingleEventWidget(state),
  emptyStateVisible: isEmptyStateNotificationVisible(state),
  mobile: isMobile(state),
})

export const Layout = connect<LayoutOwnProps, LayoutStateProps>(mapRuntime)(Presentation)
