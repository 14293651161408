import {getDayMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../../../commons/selectors/environment'
import {closeMonthlyCalendarEvent, closeMonthlyCalendarPopup} from '../../../../../../actions/calendar-layout'
import {getSelectedDayEventsCount} from '../../../../../../selectors/calendar-layout'
import {getEventById} from '../../../../../../selectors/events'
import {
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../../../../selectors/list-settings'
import {getShareFontSize} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventDetails as MonthlyCalendarEventDetailsPresentation} from './monthly-calendar-event-details'

export interface MonthlyCalendarEventDetailsOwnProps {}

export interface MonthlyCalendarEventDetailsRuntimeProps {
  event: ExtendedEvent
  dayEventCount: number
  dateText: string
  showImage: boolean
  showLocation: boolean
  imagePosition: number
  imageOpacity: number
  showFullDate: boolean
  showDescription: boolean
  showShare: boolean
  showMembers: boolean
  locationAndDateFormat: number
  fullLocale: string
  shareFontSize: number
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  closeMonthlyCalendarEvent: typeof closeMonthlyCalendarEvent
}

export interface MonthlyCalendarEventDetailsProps
  extends MonthlyCalendarEventDetailsRuntimeProps,
    MonthlyCalendarEventDetailsOwnProps,
    WithNamespaces {}

const mapRuntime = (context: AppProps): MonthlyCalendarEventDetailsRuntimeProps => {
  const {state, actions} = context
  const event = getEventById(state, state.calendarLayout.monthly.selectedEventId)

  return {
    event,
    dayEventCount: getSelectedDayEventsCount(context),
    dateText: getDayMonthText(state.calendarLayout.monthly.selectedDate, getFullLocale(state)),
    showImage: Boolean(event.mainImage) && isImageVisible(state),
    showLocation: isLocationVisible(state),
    imagePosition: state.component.settings.listImagePosition,
    imageOpacity: state.component.settings.listImageOpacity,
    showFullDate: isFullDateVisible(state),
    showDescription: isDescriptionVisible(state),
    showShare: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    locationAndDateFormat: state.component.settings.listLocationAndDateFormat,
    fullLocale: getFullLocale(state),
    shareFontSize: parseInt(getShareFontSize(context)),
    closeMonthlyCalendarPopup: () => actions.closeMonthlyCalendarPopup(),
    closeMonthlyCalendarEvent: () => actions.closeMonthlyCalendarEvent(),
  }
}

export const MonthlyCalendarEventDetails = connect<
  MonthlyCalendarEventDetailsOwnProps,
  MonthlyCalendarEventDetailsRuntimeProps
>(mapRuntime)(withNamespaces()(MonthlyCalendarEventDetailsPresentation))
