import {getAlignmentClassName} from '../../../selectors/classes'
import {isOnImageLayout} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Divider as DividerPresentation} from './divider'

interface DividerOwnProps {
  width: number
  height: number
}

interface DividerStateProps {
  alignmentClassName: string
  onImage: boolean
}

export interface DividerProps extends DividerOwnProps, DividerStateProps {}

const mapState = ({state}: AppProps): DividerStateProps => ({
  alignmentClassName: getAlignmentClassName(state.component.settings.alignment),
  onImage: isOnImageLayout(state),
})

export const Divider = connect<DividerOwnProps, DividerStateProps>(mapState)(DividerPresentation)
