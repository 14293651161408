import {getFullLocale} from '../../../../commons/selectors/environment'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersEnabled,
  isMembersVisible,
  isSocialShareVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {getImageRatio} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as CardPresentation} from './card'

interface CardStateProps {
  showImage: boolean
  showDate: boolean
  showVenue: boolean
  showFullDate: boolean
  showLocation: boolean
  showDescription: boolean
  showSocialBar: boolean
  showMembers: boolean
  membersEnabled: boolean
  hideAdditionalComponents: boolean
  imageRatio: '1:1' | '16:9'
  imageOpacity: number
  alignment: number
  fullLocale: string
}

interface CardOwnProps {
  event: ExtendedEvent
  t: Function
}

export interface CardProps extends CardStateProps, CardOwnProps {}

const mapRuntime = ({state, host: {dimensions}, actions}: AppProps, {event}: CardOwnProps): CardStateProps => ({
  showImage: isImageVisible(state),
  showDate: isDateVisible(state),
  showVenue: isVenueVisible(state, dimensions),
  showFullDate: isFullDateVisible(state),
  showLocation: isLocationVisible(state),
  showDescription: isDescriptionVisible(state),
  showSocialBar: isSocialShareVisible(state),
  showMembers: isMembersVisible(state, event),
  membersEnabled: isMembersEnabled(state),
  hideAdditionalComponents: isAdditionalComponentsHidden(state),
  imageRatio: getImageRatio(state),
  imageOpacity: state.component.settings.listImageOpacity,
  alignment: state.component.settings.listAlignment,
  fullLocale: getFullLocale(state),
})

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(CardPresentation)
