import {getDayMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../../../../../commons/selectors/environment'
import {closeMonthlyCalendarEvent} from '../../../../../../../../actions/calendar-layout'
import {
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../../../../../../selectors/list-settings'
import {AppProps} from '../../../../../../../app/interfaces'
import {connect} from '../../../../../../../runtime-context/connect'
import {MobileMonthlyEventDetails as MobileMonthlyEventDetailsPresentation} from './mobile-monthly-event-details'

export interface MobileMonthlyEventDetailsOwnProps {
  event: ExtendedEvent
  date: Date
}

export interface MobileMonthlyEventDetailsRuntimeProps {
  dateText: string
  imagePosition: number
  imageOpacity: number
  locationAndDateFormat: number
  showImage: boolean
  showLocation: boolean
  showFullDate: boolean
  showDescription: boolean
  showShare: boolean
  showMembers: boolean
  fullLocale: string
  closeMonthlyCalendarEvent: typeof closeMonthlyCalendarEvent
}

export interface MobileMonthlyEventDetailsProps
  extends MobileMonthlyEventDetailsRuntimeProps,
    MobileMonthlyEventDetailsOwnProps,
    WithNamespaces {}

const mapRuntime = (
  {state, actions}: AppProps,
  {event}: MobileMonthlyEventDetailsOwnProps,
): MobileMonthlyEventDetailsRuntimeProps => ({
  dateText: getDayMonthText(state.calendarLayout.monthly.selectedDate, getFullLocale(state)),
  imagePosition: state.component.settings.listImagePosition,
  imageOpacity: state.component.settings.listImageOpacity,
  locationAndDateFormat: state.component.settings.listLocationAndDateFormat,
  showImage: Boolean(event.mainImage) && isImageVisible(state),
  showLocation: isLocationVisible(state),
  showFullDate: isFullDateVisible(state),
  showDescription: isDescriptionVisible(state),
  showShare: isSocialShareVisible(state),
  showMembers: isMembersVisible(state, event),
  fullLocale: getFullLocale(state),
  closeMonthlyCalendarEvent: actions.closeMonthlyCalendarEvent,
})

export const MobileMonthlyEventDetails = connect<
  MobileMonthlyEventDetailsOwnProps,
  MobileMonthlyEventDetailsRuntimeProps
>(mapRuntime)(withNamespaces()(MobileMonthlyEventDetailsPresentation))
