import * as React from 'react'
import {isEventExternalRegistration} from '../../../commons/selectors/event'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RsvpButton as RsvpButtonPresentation} from './rsvp-button'

export interface RsvpButtonProps extends ButtonOwnProps, ButtonStateProps {}

interface ButtonOwnProps {
  event: wix.events.Event
  dataHook?: string
  fullWidth?: boolean
  minWidth?: number
  maxWidth?: number
  additionalStyle?: React.CSSProperties
}

interface ButtonStateProps {
  text: string
  style: number
  navigateToPage: Function
}

const mapRuntime = ({state, actions}: AppProps, {event}: ButtonOwnProps): ButtonStateProps => ({
  navigateToPage: () => {
    if (isEventExternalRegistration(event)) {
      window.open(event.registration.external.registration, '_blank')
    }
    actions.navigateToPage(event, state)
  },
  text: state.component.settings.texts.listButtonText,
  style: state.component.settings.listButtonStyle,
})

export const RsvpButton = connect<ButtonOwnProps, ButtonStateProps>(mapRuntime)(RsvpButtonPresentation)
