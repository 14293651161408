import * as classNames from 'classnames'
import * as React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import {MobileMonthlyEventProps} from '.'
import * as sc from '../../../../../../classes.scss'
import {MobileMonthlyEventDetails} from './details'
import * as s from './mobile-monthly-event.scss'

export class MobileMonthlyEvent extends React.PureComponent<MobileMonthlyEventProps> {
  handleEventClick = () => {
    const {event, date, openMonthlyCalendarEvent} = this.props
    openMonthlyCalendarEvent(date, event.id)
  }

  renderShortEventInfo() {
    const {event, eventTime} = this.props

    return (
      <div
        className={classNames(s.container, sc.textLineHeight)}
        onClick={this.handleEventClick}
        data-hook={`event-${event.id}`}
      >
        <div className={s.time} data-hook={`event-time-${event.id}`}>
          {eventTime}
        </div>
        <div className={s.title} data-hook={`event-title-${event.id}`}>
          {event.title}
          <div className={s.chevron}>
            <ChevronDown size="1.2em" />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {selectedEventId, event, date} = this.props

    if (selectedEventId === event.id) {
      return <MobileMonthlyEventDetails event={event} date={date} />
    } else {
      return this.renderShortEventInfo()
    }
  }
}
